import React from 'react';
import { WithChildren } from '../../utils/types';
import Stepper from '../Stepper/Stepper';
import styles from './Journey.module.scss';

interface IOwnProps {
  currentStep: number;
  name: string;
  stepTitles: string[];
}

type IJourneyProps = WithChildren<IOwnProps>;

export const JourneyComp = ({
  name,
  stepTitles,
  currentStep,
  children,
}: IJourneyProps) => (
  <div className={styles.journeyContainer}>
    <span className={styles.nameText}>{stepTitles[currentStep]}</span>
    <div className={styles.childrenContainer}>{children}</div>
    <Stepper currentStep={currentStep} stepTitles={stepTitles} />
  </div>
);

export default JourneyComp;
