import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import history from './history';
import { PrivateRoute } from './routing/PrivateRoute';
import './styles/App.scss';
import CreateRecipes from './views/CreateRecipe';
import Planner from './views/Planner';
import Recipe from './views/Recipe';
import Recipes from './views/Recipes';
import Welcome from './views/Welcome';

const NotFound = () => (
  <h1 style={{ color: 'black' }}>Sorry, that page was not found</h1>
);

const App = () => (
  <Router history={history}>
    <div className="App">
      <Switch>
        {/* Public Routes */}
        <Route exact path="/login" render={() => <Welcome isLogin />} />
        <Route exact path="/register" render={() => <Welcome />} />
        {/* Private Routes */}
        <PrivateRoute exact path="/planner" component={Planner} />
        <PrivateRoute exact path="/recipes" component={Recipes} />
        <PrivateRoute exact path="/recipes/create" component={CreateRecipes} />
        <PrivateRoute exact path="/recipes/:id" component={Recipe} />

        {/* Redirect empty url to login */}
        <Route exact path="/" render={() => <Redirect to="/login" />} />

        {/* If no matches, URL is not found */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </div>
  </Router>
);

export default App;
