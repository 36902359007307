import ITokenResponseData from '../interfaces/responses/token-response';

export const getKey = (
  key: string,
  asJson: boolean = false,
  defaultValue: boolean | null = null
): string | boolean | null => {
  try {
    const value =
      asJson && checkKeyExists(key)
        ? JSON.parse(localStorage.getItem(key) || '{}')
        : localStorage.getItem(key);
    return value === null ? defaultValue : value;
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export const saveKey = (
  key: string,
  data: any,
  asJson: boolean = false
): void | null => {
  try {
    return asJson
      ? localStorage.setItem(key, JSON.stringify(data))
      : localStorage.setItem(key, data);
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export const deleteKey = (key: string): void | null => {
  try {
    return localStorage.removeItem(key);
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export const checkKeyExists = (key: string): boolean => {
  if (localStorage.getItem(key)) {
    return true;
  }
  return false;
};

export const getAuthInfo = (
  property: string | null = null
): ITokenResponseData | string | null => {
  try {
    let value = checkKeyExists('access_token')
      ? JSON.parse(localStorage.getItem('access_token') || '{}')
      : null;
    value = property && value ? value[property] : (value as ITokenResponseData);
    return value;
  } catch (e) {
    console.warn(e);
    return null;
  }
};
