import BackspaceIcon from '@material-ui/icons/Backspace';
import { FieldArrayRenderProps, useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import { IRecipeToIngredients } from '../../../interfaces/requests/submit-recipe-request';
import InteractableWrapper from '../../utils/InteractableWrapper/InteractableWrapper';
import styles from './IngredientField.module.scss';
import { options } from '../../../utils/UOMOptions';

interface IOwnProps {
  id: number;
  arrayHelpers: FieldArrayRenderProps;
}

type IIngredientFieldProps = IOwnProps;

export const IngredientFieldComp = ({
  id,
  arrayHelpers,
}: IIngredientFieldProps) => {
  const { values } = useFormikContext<any>();
  const ingredient: IRecipeToIngredients = values.ingredients.find(
    (e: IRecipeToIngredients) => e.ingredientId === id
  );
  const [value, setValue] = useState<number>(parseInt(ingredient.UOMCount, 10));
  const ingredientIndex = values.ingredients
    .map((element: IRecipeToIngredients) => element.ingredientId)
    .indexOf(ingredient.ingredientId);

  const handleChangeAmount = useCallback(
    (updatedIngredient: IRecipeToIngredients) => {
      arrayHelpers.replace(ingredientIndex, updatedIngredient);
    },
    [arrayHelpers, ingredientIndex]
  );

  const onAmountChange = useCallback(
    (e: React.ChangeEvent<any>) => {
      const newValue = parseInt(e.target.value, 10);

      if (isNaN(newValue) || newValue < 1) {
        // not a valid number input
        return;
      }
      setValue(newValue);

      // update ingredient list with updated ingredient
      const updatedIngredient: IRecipeToIngredients = {
        ...ingredient,
        UOMCount: newValue.toString(),
      };
      handleChangeAmount(updatedIngredient);
    },
    [handleChangeAmount, ingredient]
  );

  const onUOMChange = useCallback(
    (
      e: {
        value: string;
        label: string;
      } | null
    ) => {
      if (e === null) {
        console.error('new UOM value is null');
        return;
      }
      // update ingredient list with updated ingredient
      const updatedIngredient: IRecipeToIngredients = {
        ...ingredient,
        foodUOM: e.value,
      };
      handleChangeAmount(updatedIngredient);
    },
    [handleChangeAmount, ingredient]
  );

  return (
    <div className={styles.ingredientFieldWrapper}>
      <div className={styles.numberInputContainer}>
        <input
          className={styles.numberInput}
          type="number"
          onChange={onAmountChange}
          value={value}
        />
      </div>
      <Select
        className={styles.unitSelect}
        options={options}
        defaultValue={options[0]}
        onChange={onUOMChange}
      />
      <span className={styles.ingredientText}>{ingredient.name}</span>
      <div className={styles.removeButton}>
        <InteractableWrapper
          onInteract={() => arrayHelpers.remove(ingredientIndex)}
        >
          <BackspaceIcon />
        </InteractableWrapper>
      </div>
    </div>
  );
};

export default IngredientFieldComp;
