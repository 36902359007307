import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import initial from './initial';
import reducer from './reducer';
import IState from './state';

const store: Store<IState> = createStore(
  reducer,
  initial,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
