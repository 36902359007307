import { FormikErrors, FormikTouched } from 'formik/dist/types';
import React from 'react';
import styles from './Field.module.scss';

interface IOwnProps {
  errors:
    | string
    | FormikErrors<any>
    | FormikErrors<any>[]
    | string[]
    | undefined;
  Icon?: any;
  isTouched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  label: string;
  name: string;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onChange: (e: React.ChangeEvent<any>) => void;
  type?: string;
  value: any;
}

type IFieldProps = IOwnProps;

// Component is designed to take in Formik onBlur and onChange functions, it doesn't manage its own state
export const FieldComp = ({
  errors,
  Icon,
  label,
  isTouched,
  name,
  onBlur,
  onChange,
  type = 'text',
  value,
}: IFieldProps) => {
  const getLabelClassNames = () =>
    `${styles.name} ${name && value.length === 0 ? styles.nameHidden : ''}`;

  return (
    <div className={styles.field}>
      <label>
        <span className={getLabelClassNames()}>{label}</span>
        <div className={styles.inputWrapper}>
          <input
            name={name}
            className={styles.input}
            type={type}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={label}
          />
          {Icon && <div className={styles.rightIcon}>{Icon}</div>}
        </div>
      </label>
      {isTouched && errors ? (
        <span className={styles.errorText}>{errors}</span>
      ) : null}
    </div>
  );
};

export default FieldComp;
