import IState from '../state';

const setIsAuthenticated = (
  state: IState,
  { isAuthenticated }: any
): IState => ({
  ...state,
  isAuthenticated,
});

export default setIsAuthenticated;
