import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { searchIngredients } from '../../../../data/redux/actions/api';
import { IRecipeToIngredients } from '../../../../interfaces/requests/submit-recipe-request';
import IIngredientSearchResponse from '../../../../interfaces/responses/ingredient-search-response';
import Button from '../../../Button/Button';
import IngredientField from '../../../Field/IngredientField/IngredientField';
import styles from './AddIngredients.module.scss';

interface IOwnProps {
  handleStepChange: (stepIncrement: 1 | -1) => void;
}

type IAddIngredientsProps = IOwnProps;

export const AddIngredientsComp = ({
  handleStepChange,
}: IAddIngredientsProps) => {
  const { values, setFieldTouched } = useFormikContext<any>();
  const [selectValue, setSelectValue] = useState(null);

  const promiseOptions = (
    inputValue: string
  ): Promise<IIngredientSearchResponse[]> =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(searchIngredients(inputValue));
      }, 1000);
    });

  const handleOnChange = (
    selected: any,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    // resets the async select component value
    setSelectValue(null);
    const { id, name }: IIngredientSearchResponse = selected;
    const ingredientToAdd: IRecipeToIngredients = {
      ingredientId: id,
      order: 0,
      UOMCount: '1',
      foodUOM: 'x',
      name,
    };

    const ingredientExists: boolean = values.ingredients.some(
      (ingredient: IRecipeToIngredients) =>
        ingredient.ingredientId === ingredientToAdd.ingredientId
    );

    if (!ingredientExists) {
      setFieldTouched('ingredients');
      arrayHelpers.push(ingredientToAdd);
    }
  };

  return (
    <>
      <div className={styles.inputArea}>
        <FieldArray
          name="ingredients"
          render={(arrayHelpers) => (
            <>
              <AsyncSelect
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id.toString()}
                value={selectValue}
                defaultOptions
                loadOptions={promiseOptions}
                onChange={(e) => handleOnChange(e, arrayHelpers)}
              />
              <div className={styles.ingredientFields}>
                {values.ingredients.map((ingredient: IRecipeToIngredients) => (
                  <IngredientField
                    arrayHelpers={arrayHelpers}
                    key={ingredient.ingredientId}
                    id={ingredient.ingredientId}
                  />
                ))}
              </div>
            </>
          )}
        />
      </div>
      <div className={styles.buttonArea}>
        <Button
          label="Continue"
          onClick={() => handleStepChange(1)}
          disabled={values.ingredients.length < 1}
        />
        <Button label="Back" onClick={() => handleStepChange(-1)} />
      </div>
    </>
  );
};

export default AddIngredientsComp;
