import React from 'react';
import loadingSpinner from '../../assets/loading-spinner-ball-triangle.svg';
import styles from './LoadingSpinner.module.scss';

interface IOwnProps {
  overlay?: boolean;
}

export const LoadingSpinner: React.FC<IOwnProps> = ({ overlay = false }) => {
  const getClassNames = (): string =>
    `${styles.spinnerContainer} ${overlay && styles.spinnerContainerOverlay}`;

  return (
    <div className={getClassNames()}>
      <img
        className={styles.spinnerImage}
        src={loadingSpinner}
        alt="loading spinner"
      />
    </div>
  );
};
