import IState from './state';

const initial: IState = {
  isAuthenticated: false,
  isLoading: false,
  mealPlan: { recipes: [] },
  recipes: { totalPages: 0, list: [] },
  currentRecipe: undefined,
  user: {},
};

export default initial;
