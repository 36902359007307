import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as Yup from 'yup';
import { registerUser } from '../../data/redux/actions/api';
import IState from '../../data/redux/state';
import { IRegisterUserRequest } from '../../interfaces/requests/register-user-request';
import Button from '../Button/Button';
import { Field, PasswordField } from '../Field';
import styles from './Register.module.scss';

interface IDispatchProps {
  handleRegisterUser: (data: IRegisterUserRequest) => any;
}

const RegisterComp: React.FC<IDispatchProps> = ({ handleRegisterUser }) => (
  <>
    <h1 className={styles.heading}>Register User</h1>
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      }}
      validationSchema={Yup.object({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        email: Yup.string()
          .email('Invalid Email Address')
          .required('Email Address is required'),
        password: Yup.string()
          .required('Password is required')
          .min(8, 'Password must be at least 8 characters')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
          ),
      })}
      onSubmit={(values) => {
        handleRegisterUser(values);
      }}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form className={styles.form} onSubmit={handleSubmit}>
          <Field
            name="firstName"
            label="First Name"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            isTouched={touched.firstName}
            errors={errors.firstName}
          />
          <Field
            name="lastName"
            label="Last Name"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            isTouched={touched.lastName}
            errors={errors.lastName}
          />
          <Field
            name="email"
            label="Email Address"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            isTouched={touched.email}
            errors={errors.email}
          />
          <PasswordField
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            isTouched={touched.password}
            errors={errors.password}
          />
          <Button
            label="Register"
            disabled={!touched.firstName || errors.firstName !== undefined}
            submit
          />
        </form>
      )}
    </Formik>
  </>
);

function mapDispatchToProps(
  dispatch: ThunkDispatch<IState, void, any>
): IDispatchProps {
  return {
    handleRegisterUser: (data: IRegisterUserRequest): any => {
      dispatch(registerUser(data));
    },
  };
}

export default connect<any, IDispatchProps, any, any>(
  null,
  mapDispatchToProps
)(RegisterComp);
