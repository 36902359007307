import React from 'react';
import { connect } from 'react-redux';
import IState from '../../data/redux/state';
import IMealPlanGenerationResponse from '../../interfaces/responses/meal-plan-generation-response';
import Generate from './Generate/Generate';

interface IStoreProps {
  mealPlan: IMealPlanGenerationResponse;
}

export const PlannerComp: React.FC<IStoreProps> = ({ mealPlan }) => {
  const { recipes } = mealPlan;

  return (
    <>
      <Generate />
      {recipes.length > 0 && (
        <ol>
          {recipes.map((recipe) => (
            <li key={recipe.id}>{recipe.name}</li>
          ))}
        </ol>
      )}
    </>
  );
};

function mapStateToProps(state: IState) {
  return {
    mealPlan: state.mealPlan,
  };
}

export default connect<IStoreProps, any, any, IState>(mapStateToProps)(
  PlannerComp
);
