import { useFormikContext } from 'formik';
import React from 'react';
import Button from '../../../Button/Button';
import { Field } from '../../../Field';
import styles from './AddTitle.module.scss';

interface IOwnProps {
  handleStepChange: (stepIncrement: 1 | -1) => void;
}

type IAddTitleProps = IOwnProps;

export const AddTitleComp = ({ handleStepChange }: IAddTitleProps) => {
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
  } = useFormikContext<any>();

  return (
    <>
      <div className={styles.inputArea}>
        <Field
          label="Title"
          name="title"
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.title}
          errors={errors.title}
        />
      </div>
      <div className={styles.buttonArea}>
        <Button
          label="Continue"
          onClick={() => handleStepChange(1)}
          disabled={!touched.title || errors.title !== undefined}
        />
      </div>
    </>
  );
};

export default AddTitleComp;
