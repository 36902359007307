import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import history from '../../history';
import Card from '../Card/Card';
import styles from './CardGrid.module.scss';

interface IOwnProps {
  data: IDataElement[];
  firstCard?: {
    title: string;
    icon: OverridableComponent<any>;
    onClick: () => void;
  };
  infiniteScroll?: {
    lastElementCallback: (node: any) => void;
  };
  enableNavigate?: boolean;
}

interface IDataElement {
  id: number;
  name: string;
  rating: number;
}

export const CardGridComp = ({
  data,
  firstCard,
  infiniteScroll,
  enableNavigate = false,
}: IOwnProps) => {
  const location = useLocation();

  const handleSettingRef = useCallback(
    (index: number) => {
      if (infiniteScroll !== undefined && data.length === index + 1) {
        return infiniteScroll.lastElementCallback;
      }
      return undefined;
    },
    [data.length, infiniteScroll]
  );

  const handleNavigate = React.useCallback(
    (element: IDataElement) => {
      enableNavigate &&
        history.push(`${location.pathname}/${element.id.toString()}`);
    },
    [enableNavigate, location.pathname]
  );

  return (
    <div className={styles.cardGrid}>
      {firstCard !== undefined && (
        <Card
          title={firstCard.title}
          Icon={firstCard.icon}
          onClick={firstCard.onClick}
        />
      )}
      {data.map((element, index) => (
        <Card
          title={element.name}
          rating={element.rating}
          key={element.id}
          ref={handleSettingRef(index)}
          onClick={() => handleNavigate(element)}
        />
      ))}
    </div>
  );
};

export default CardGridComp;
