import Layout from '../components/Layout/Layout';
import Recipes from '../components/Recipes/Recipes';

export const RecipesView: React.FC = () => (
  <Layout>
    <Recipes />
  </Layout>
);

export default RecipesView;
