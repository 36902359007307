import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, { useState } from 'react';
import InteractableWrapper from '../../utils/InteractableWrapper/InteractableWrapper';
import Field from '../Field/Field';

interface IOwnProps {
  errors?: string;
  isTouched?: boolean;
  onBlur: (e: React.FocusEvent<any>) => void;
  onChange: (e: React.ChangeEvent<any>) => void;
  value: any;
}

type IPasswordFieldProps = IOwnProps;

export const PasswordFieldComp = ({
  errors,
  isTouched,
  value,
  onBlur,
  onChange,
}: IPasswordFieldProps) => {
  const [passwordVisible, setPasswordVisible] = useState(true);

  const getClickableIcon = () => (
    <InteractableWrapper
      onInteract={() => setPasswordVisible(!passwordVisible)}
    >
      {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </InteractableWrapper>
  );

  return (
    <Field
      errors={errors}
      Icon={getClickableIcon()}
      isTouched={isTouched}
      label="Password"
      name="password"
      onBlur={onBlur}
      onChange={onChange}
      type={passwordVisible ? 'password' : 'text'}
      value={value}
    />
  );
};

export default PasswordFieldComp;
