import axios from 'axios';
import getEnvironmentVariable from './utils/getEnvironmentVariable';

export const BackendUrl = async (url: string): Promise<string> =>
  getEnvironmentVariable(process.env.REACT_APP_API_URL) + url;

// TODO: auto set axios headers
// const setAuthHeader = (): void => {
//   try {
//     axios.defaults.headers.common["Authorization"] = `Bearer ${getToken(
//       "access_token"
//     )}`;
//   } catch (error) {
//     console.warn(
//       `axios.BackendUrl: could not set auth header. Error : ${error}`
//     );
//   }
// };

export const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache',
  },
});
