import IState from '../state';

const addToRecipes = (state: IState, { recipePageList }: any): IState => ({
  ...state,
  recipes: {
    totalPages: recipePageList.totalPages,
    list: [...state.recipes.list, ...recipePageList.data],
  },
});

export default addToRecipes;
