import DoneIcon from '@material-ui/icons/Done';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './Stepper.module.scss';

interface IOwnProps {
  currentStep: number;
  stepTitles: string[];
}

type IStepperProps = IOwnProps;

interface IStep {
  index: number;
  title: string;
  selected: boolean;
  completed: boolean;
}

export const StepperComp = ({ currentStep, stepTitles }: IStepperProps) => {
  const getCurrentSteps = useCallback(
    (): IStep[] =>
      stepTitles.map((title, index) => ({
        index,
        title,
        selected: index === currentStep,
        completed: index < currentStep,
      })),
    [currentStep, stepTitles]
  );

  const [steps, setSteps] = useState<IStep[]>(getCurrentSteps());

  useEffect(() => {
    setSteps(getCurrentSteps());
  }, [getCurrentSteps]);

  const getClassNames = (step: IStep): string => {
    let classNames = styles.step;

    if (step.selected) {
      classNames += ` ${styles.selected}`;
    }

    if (step.completed) {
      classNames += ` ${styles.completed}`;
    }
    return classNames;
  };

  return (
    <div className={styles.stepper}>
      {steps.map((step) => (
        <div key={step.index} className={getClassNames(step)}>
          <span className={styles.stepText}>
            {step.completed ? <DoneIcon /> : step.index + 1}
          </span>
        </div>
      ))}
    </div>
  );
};

export default StepperComp;
