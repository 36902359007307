import {
  ADD_TO_RECIPES,
  SET_IS_AUTHENTICATED,
  SET_IS_LOADING,
  SET_MEAL_PLAN,
  SET_RECIPES,
  SET_CURRENT_RECIPE,
} from './actions/state';
import initial from './initial';
import addToRecipes from './reducers/addToRecipes';
import setCurrentRecipe from './reducers/setCurrentRecipe';
import setIsAuthenticated from './reducers/setIsAuthenticated';
import setIsLoading from './reducers/setIsLoading';
import setMealPlan from './reducers/setMealPlan';
import setRecipes from './reducers/setRecipes';
import IState from './state';

const reducer = (state: IState = initial, action: any) => {
  switch (action.type) {
    case ADD_TO_RECIPES:
      return addToRecipes(state, action);
    case SET_IS_AUTHENTICATED:
      return setIsAuthenticated(state, action);
    case SET_IS_LOADING:
      return setIsLoading(state, action);
    case SET_MEAL_PLAN:
      return setMealPlan(state, action);
    case SET_RECIPES:
      return setRecipes(state, action);
    case SET_CURRENT_RECIPE:
      return setCurrentRecipe(state, action);
    default:
      return state;
  }
};

export default reducer;
