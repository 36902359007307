import { useFormikContext } from 'formik';
import React from 'react';
import { IRecipeToIngredients } from '../../../../interfaces/requests/submit-recipe-request';
import Button from '../../../Button/Button';
import styles from './Confirmation.module.scss';

interface IOwnProps {
  handleStepChange: (stepIncrement: 1 | -1) => void;
}

type IConfirmationProps = IOwnProps;

export const ConfirmationComp = ({ handleStepChange }: IConfirmationProps) => {
  const { values } = useFormikContext<any>();

  return (
    <>
      <div className={styles.inputArea}>
        <div style={{ textAlign: 'left' }}>
          <p>Title: {values.title}</p>
          <p>Ingredients:</p>
          <ul>
            {values.ingredients.map((ingredient: IRecipeToIngredients) => (
              <li key={ingredient.ingredientId}>
                {ingredient.UOMCount} {ingredient.foodUOM} {ingredient.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.buttonArea}>
        <Button label="Confirm" submit />
        <Button label="Back" onClick={() => handleStepChange(-1)} />
      </div>
    </>
  );
};

export default ConfirmationComp;
