import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import Layout from '../components/Layout/Layout';
import { LoadingSpinner } from '../components/LoadingSpinner/LoadingSpinner';
import Recipe from '../components/Recipe/Recipe';
import { getRecipe } from '../data/redux/actions/api';
import IState from '../data/redux/state';
import { IRecipeResponse } from '../interfaces/responses/recipe-response';

interface IStoreProps {
  currentRecipe?: IRecipeResponse;
  isLoading: boolean;
}

interface IDispatchProps {
  handleGetRecipe: (id: number) => void;
}

type IRecipeViewProps = IStoreProps & IDispatchProps;

export const RecipeView: React.FC<IRecipeViewProps> = ({
  currentRecipe,
  isLoading,
  handleGetRecipe,
}) => {
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    // if the recipe is already in state, don't get it again
    // what if it's been updated? might need the call anyway
    // Could show whatever's there currently, lazy load in the background? don't enable edit until loading finished
    if (currentRecipe?.id.toString() !== id) {
      handleGetRecipe(parseInt(id, 10));
    }
  }, [currentRecipe?.id, handleGetRecipe, id]);

  // TODO: if api is requested and finished loading, but currentRecipe is still undefined, redirect to recipes page
  return (
    <Layout>
      {isLoading && <LoadingSpinner />}
      {currentRecipe && <Recipe recipe={currentRecipe} />}
    </Layout>
  );
};

function mapStateToProps(state: IState) {
  return {
    currentRecipe: state.currentRecipe,
    isLoading: state.isLoading,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<IState, void, any>
): IDispatchProps {
  return {
    handleGetRecipe: (id: number): void => {
      dispatch(getRecipe(id));
    },
  };
}

export default connect<IStoreProps, IDispatchProps, any, IState>(
  mapStateToProps,
  mapDispatchToProps
)(RecipeView);
