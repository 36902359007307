// some labels add whitespace before
export const options = [
  { value: 'x', label: ' x' },
  { value: 'ml', label: 'ml' },
  { value: 'pinch', label: ' pinch' },
  { value: 'g', label: 'g' },
  { value: 'handful', label: ' handful' },
  { value: 'cup', label: ' cup' },
  { value: 'tsp', label: 'tsp' },
  { value: 'tbsp', label: 'tbsp' },
];

export const getUOMLabel = (value: string): string => {
  const option = options.find((o) => o.value === value);
  if (option?.label) {
    return option?.label;
  }
  console.error(`Couldn't find UOM with value of ${value}, returning value`);
  return value;
};

export default options;
