import { Formik } from 'formik';
import { motion, useMotionValue } from 'framer-motion';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as Yup from 'yup';
import { submitRecipe } from '../../../data/redux/actions/api';
import IState from '../../../data/redux/state';
import { ISubmitRecipeRequest } from '../../../interfaces/requests/submit-recipe-request';
import Journey from '../../Journey/Journey';
import AddIngredients from '../JourneyStages/AddIngredients/AddIngredients';
import AddTitle from '../JourneyStages/AddTitle/AddTitle';
import Confirmation from '../JourneyStages/Confirmation/Confirmation';
import styles from './CreateRecipe.module.scss';

interface IDispatchProps {
  handleAddRecipe: (data: ISubmitRecipeRequest) => any;
}

type IRecipesProps = IDispatchProps;

export const CreateRecipeComp: React.FC<IRecipesProps> = ({
  handleAddRecipe,
}) => {
  const [step, setStep] = useState(0);
  const [stage, setStage] = useState(0);
  const [animationValue, setAnimationValue] = useState('0');
  const initialAnimationValue = 100;
  const transitionDurationSeconds = 0.3;
  const transitionDurationMilliseconds = transitionDurationSeconds * 1000;
  const x = useMotionValue('0');

  const handleStepChange = (stepIncrement: 1 | -1): void => {
    const animateTo = `${initialAnimationValue * -stepIncrement}vw`;
    const nextAnimateFrom = `${initialAnimationValue * stepIncrement}vw`;

    setAnimationValue(animateTo);
    setStep(step + stepIncrement);
    setTimeout(() => {
      // stage must be set after timeout so text doesn't change before animating out
      setStage(stage + stepIncrement);
      x.set(nextAnimateFrom);
      setAnimationValue('0');
    }, transitionDurationMilliseconds);
  };

  const renderStage = (): JSX.Element => {
    switch (stage) {
      case 0:
        return <AddTitle handleStepChange={handleStepChange} />;
      case 1:
        return <AddIngredients handleStepChange={handleStepChange} />;
      case 2:
        return <Confirmation handleStepChange={handleStepChange} />;
      default:
        console.error('invalid stage number set');
        return <span>Error</span>;
    }
  };

  return (
    <Formik
      initialValues={{
        title: '',
        ingredients: [],
      }}
      validationSchema={Yup.object({
        title: Yup.string().required('Your recipe needs a title'),
        ingredients: Yup.array()
          .min(1)
          .required('Your recipe needs an ingredient'),
      })}
      onSubmit={(values) => {
        const recipeData: ISubmitRecipeRequest = {
          name: values.title,
          recipeToIngredients: values.ingredients,
        };

        handleAddRecipe(recipeData);
      }}
    >
      {(props) => (
        <Journey
          currentStep={step}
          name="Create a recipe"
          stepTitles={[
            "First, let's add a title",
            `${props.values.title} needs some ingredients!`,
            `Ready to submit your recipe?`,
          ]}
        >
          <motion.div
            className={styles.createRecipe}
            initial={{ x: `${initialAnimationValue}vw` }}
            animate={{ x: animationValue }}
            style={{ x }}
            transition={{
              type: 'spring',
              stiffness: 50,
              duration: transitionDurationSeconds,
            }}
          >
            <form
              onSubmit={props.handleSubmit}
              className={styles.stageContainer}
            >
              {renderStage()}
            </form>
          </motion.div>
        </Journey>
      )}
    </Formik>
  );
};

function mapDispatchToProps(
  dispatch: ThunkDispatch<IState, void, any>
): IDispatchProps {
  return {
    handleAddRecipe: (data): any => {
      dispatch(submitRecipe(data));
    },
  };
}
export default connect<any, IDispatchProps, any, IState>(
  null,
  mapDispatchToProps
)(CreateRecipeComp);
