import { Rating } from '@material-ui/lab';
import React, { useMemo } from 'react';
import { IRecipeResponse } from '../../interfaces/responses/recipe-response';
import { getUOMLabel } from '../../utils/UOMOptions';

interface IOwnProps {
  recipe: IRecipeResponse;
}

const RecipeComp: React.FC<IOwnProps> = ({ recipe }) => {
  const recipeIngredients = useMemo(
    () =>
      recipe.recipeToIngredients.map(
        ({ id, ingredient, foodUOM, uomCount }) => (
          <li key={id}>
            {uomCount}
            {getUOMLabel(foodUOM)} {ingredient.name}
          </li>
        )
      ),
    [recipe.recipeToIngredients]
  );

  return (
    <div>
      <h2>{recipe.name}</h2>
      {recipe.rating > 0 && <Rating value={recipe.rating} readOnly />}
      <h3>Ingredients</h3>
      <ul>{recipeIngredients}</ul>
      {recipe.instructions && (
        <>
          <h3>Method</h3>
          <p>{recipe.instructions}</p>
        </>
      )}
    </div>
  );
};

export default RecipeComp;
