import IMealPlanGenerationResponse from '../../../interfaces/responses/meal-plan-generation-response';
import IPageListResponse from '../../../interfaces/responses/page-list-response';
import IRecipeListResponse from '../../../interfaces/responses/recipe-list-response';
import { IRecipeResponse } from '../../../interfaces/responses/recipe-response';

export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
export const SET_RECIPES = 'SET_RECIPES';
export const ADD_TO_RECIPES = 'ADD_TO_RECIPES';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_MEAL_PLAN = 'SET_MEAL_PLAN';
export const SET_CURRENT_RECIPE = 'SET_CURRENT_RECIPE';

export const setIsAuthenticated = (): any => ({
  type: SET_IS_AUTHENTICATED,
  isAuthenticated: true,
});

export const setRecipes = (
  recipePageList: IPageListResponse<IRecipeListResponse>
): any => ({
  type: SET_RECIPES,
  recipePageList,
});

export const addToRecipes = (
  recipePageList: IPageListResponse<IRecipeListResponse>
): any => ({
  type: ADD_TO_RECIPES,
  recipePageList,
});

export const setIsLoading = (value: boolean): any => ({
  type: SET_IS_LOADING,
  isLoading: value,
});

export const setMealPlan = (mealPlan: IMealPlanGenerationResponse): any => ({
  type: SET_MEAL_PLAN,
  mealPlan,
});

export const setCurrentRecipe = (currentRecipe: IRecipeResponse): any => ({
  type: SET_CURRENT_RECIPE,
  currentRecipe,
});
