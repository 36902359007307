import { connect } from 'react-redux';
import Layout from '../components/Layout/Layout';
import { LoadingSpinner } from '../components/LoadingSpinner/LoadingSpinner';
import Planner from '../components/Planner/Planner';
import IState from '../data/redux/state';

interface IStoreProps {
  isLoading: boolean;
}

export const PlannerView: React.FC<IStoreProps> = ({ isLoading }) => (
  <Layout>
    {isLoading && <LoadingSpinner overlay />}
    <Planner />
  </Layout>
);

function mapStateToProps(state: IState) {
  return { isLoading: state.isLoading };
}

export default connect<IStoreProps, {}, any, IState>(mapStateToProps)(
  PlannerView
);
