import { Redirect, Route, useLocation } from 'react-router';
import moment from 'moment';
import { getAuthInfo, deleteKey } from '../utils/localStorage';
import ITokenResponseData from '../interfaces/responses/token-response';

interface IProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

export const PrivateRoute = ({ component: Component, ...rest }: IProps) => {
  const currentLocation = useLocation();
  const originalUrl = sessionStorage.getItem('redirectOriginialPath');

  const isAuthenticated = () => {
    const authToken = getAuthInfo() as ITokenResponseData;
    const lessThanOneHourAgo = moment(authToken?.expiresUtc).isAfter(
      moment().subtract(1, 'hours')
    );

    if (authToken && lessThanOneHourAgo) {
      sessionStorage.removeItem('redirectOriginialPath');
      return true;
    }

    sessionStorage.setItem('redirectOriginialPath', currentLocation.pathname);
    deleteKey('access_token');

    return false;
  };

  if (isAuthenticated()) {
    if (originalUrl) {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{ pathname: originalUrl, state: { from: props.location } }}
            />
          )}
        />
      );
    }
    return (
      <Route
        {...rest}
        render={(props) => (
          <Component key={props.match.params.id || 'empty'} {...props} />
        )}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )}
    />
  );
};
