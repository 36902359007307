import { connect } from 'react-redux';
import { CreateRecipe } from '../components/CreateRecipe';
import Layout from '../components/Layout/Layout';
import { LoadingSpinner } from '../components/LoadingSpinner/LoadingSpinner';
import IState from '../data/redux/state';

interface IStoreProps {
  isLoading: boolean;
}

const CreateRecipesView: React.FC<IStoreProps> = ({ isLoading }) => (
  <Layout>
    {isLoading && <LoadingSpinner overlay />}
    <CreateRecipe />
  </Layout>
);

function mapStateToProps(state: IState) {
  return { isLoading: state.isLoading };
}

export default connect<IStoreProps, {}, any, IState>(mapStateToProps)(
  CreateRecipesView
);
