import React from 'react';
import { connect } from 'react-redux';
import Layout from '../components/Layout/Layout';
import { LoadingSpinner } from '../components/LoadingSpinner/LoadingSpinner';
import Login from '../components/Login/Login';
import Register from '../components/Register/Register';
import IState from '../data/redux/state';

interface IStoreProps {
  isLoading: boolean;
}

interface IOwnProps {
  isLogin: boolean;
}

type IWelcomeViewProps = IStoreProps & IOwnProps;

export const WelcomeView: React.FC<IWelcomeViewProps> = ({
  isLoading,
  isLogin = false,
}) => (
  <Layout sidebar={false}>
    {isLoading && <LoadingSpinner overlay />}
    {isLogin ? <Login /> : <Register />}
  </Layout>
);

function mapStateToProps(state: IState) {
  return { isLoading: state.isLoading };
}

export default connect<IStoreProps, {}, any, IState>(mapStateToProps)(
  WelcomeView
);
