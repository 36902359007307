import IState from '../state';

const setRecipes = (state: IState, { recipePageList }: any): IState => ({
  ...state,
  recipes: {
    totalPages: recipePageList.totalPages,
    list: [...recipePageList.data],
  },
});

export default setRecipes;
