import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { axiosInstance, BackendUrl } from '../../../axios';
import history from '../../../history';
import IMealPlanGenerationRequest from '../../../interfaces/requests/meal-plan-generation-request';
import { IRegisterUserRequest } from '../../../interfaces/requests/register-user-request';
import { ISubmitRecipeRequest } from '../../../interfaces/requests/submit-recipe-request';
import IIngredientSearchResponse from '../../../interfaces/responses/ingredient-search-response';
import IMealPlanGenerationResponse from '../../../interfaces/responses/meal-plan-generation-response';
import IPageListResponse from '../../../interfaces/responses/page-list-response';
import IRecipeListResponse from '../../../interfaces/responses/recipe-list-response';
import { IRecipeResponse } from '../../../interfaces/responses/recipe-response';
import { IRegisterUserResponse } from '../../../interfaces/responses/register-user-response';
import { ISubmitRecipeResponse } from '../../../interfaces/responses/submit-recipe-response';
import ITokenResponse from '../../../interfaces/responses/token-response';
import getEnvironmentVariable from '../../../utils/getEnvironmentVariable';
import { getAuthInfo, saveKey } from '../../../utils/localStorage';
import {
  addToRecipes,
  setCurrentRecipe,
  setIsAuthenticated,
  setIsLoading,
  setMealPlan,
  setRecipes,
} from './state';

export const requestAuthentication = (
  email: string,
  password: string,
  redirect: string = '/planner'
) => (dispatch: Dispatch<any>): void => {
  dispatch(setIsLoading(true));

  const authenticationUrl = `${getEnvironmentVariable(
    process.env.REACT_APP_API_URL
  )}/authentication`;

  console.log({ authenticationUrl });

  axiosInstance
    .post(authenticationUrl, {
      email,
      password,
    })
    .then((response) => {
      const responseData: ITokenResponse = response.data;
      saveKey('access_token', responseData, true);
      console.log(`User Access Token: ${responseData.accessToken}`);

      dispatch(setIsAuthenticated());
      dispatch(setIsLoading(false));
      history.push(redirect);
    })
    .catch((axiosError: AxiosError<any>) => {
      dispatch(setIsLoading(false));
      console.error('api.ts - failed to login. \n\n', axiosError);
    });
};

export const submitRecipe = (data: ISubmitRecipeRequest) => async (
  dispatch: Dispatch<any>
): Promise<void> => {
  dispatch(setIsLoading(true));

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${getAuthInfo(
    'accessToken'
  )}`;
  const url: string = await BackendUrl('/recipe/create');
  axiosInstance
    .post(url, data)
    .then((response: AxiosResponse<ISubmitRecipeResponse>) => {
      dispatch(setIsLoading(false));

      if (response.status === 200) {
        history.push('/recipes');
      }
    })
    .catch((axiosError: AxiosError<any>) => {
      dispatch(setIsLoading(false));
      // TODO: sort this out

      const errors: any = axiosError.response?.data?.errors;
      if (errors) {
        console.table(errors['']);
      }
      console.error(
        `api.ts - failed to submit recipe, response code: ${axiosError.response?.status}. \n\n`,
        axiosError
      );
    });
};

export const getRecipeList = (
  searchTerm: string,
  pageNumber: number,
  pageSize: number
) => async (dispatch: Dispatch<any>): Promise<void> => {
  // only show loading spinner if first page
  if (pageNumber === 1) {
    dispatch(setIsLoading(true));
  }
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${getAuthInfo(
    'accessToken'
  )}`;
  const url: string = await BackendUrl('/recipe/list');
  const params = { searchTerm, pageNumber, pageSize };

  axiosInstance({
    method: 'GET',
    url,
    params,
  })
    .then((response: AxiosResponse<IPageListResponse<IRecipeListResponse>>) => {
      dispatch(setIsLoading(false));

      response.data.pageNumber === 1
        ? dispatch(setRecipes(response.data))
        : dispatch(addToRecipes(response.data));
    })
    .catch((e) => {
      dispatch(setIsLoading(false));

      if (axios.isCancel(e)) return;

      const axiosError: AxiosError<any> = e;

      console.error(
        'api.ts - failed to get recipes, axiosError = ',
        axiosError
      );
    });
};

export const searchIngredients = async (
  searchTerm: string
): Promise<IIngredientSearchResponse[]> => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${getAuthInfo(
    'accessToken'
  )}`;
  const url: string = await BackendUrl('/ingredient/ingredientsearch');
  const params = { searchTerm };

  return axiosInstance({
    method: 'GET',
    url,
    params,
  })
    .then(
      (response: AxiosResponse<IIngredientSearchResponse[]>) => response.data
    )
    .catch((e) => {
      const axiosError: AxiosError<any> = e;

      console.error(
        'api.ts - failed to get ingredients, axiosError = ',
        axiosError
      );

      return [];
    });
};

export const generateMealPlan = (params: IMealPlanGenerationRequest) => async (
  dispatch: Dispatch<any>
): Promise<any> => {
  dispatch(setIsLoading(true));
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${getAuthInfo(
    'accessToken'
  )}`;
  const url: string = await BackendUrl('/mealplan/get');
  return axiosInstance({ url, params })
    .then((response: AxiosResponse<IMealPlanGenerationResponse>) => {
      dispatch(setIsLoading(false));
      dispatch(setMealPlan(response.data));
    })
    .catch((e) => {
      dispatch(setIsLoading(false));
      const axiosError: AxiosError<any> = e;
      console.error(
        'api.ts - failed to generate meal plan, axiosError = ',
        axiosError
      );
    });
};

export const registerUser = (data: IRegisterUserRequest) => async (
  dispatch: Dispatch<any>
): Promise<any> => {
  dispatch(setIsLoading(true));

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${getAuthInfo(
    'accessToken'
  )}`;
  const url: string = await BackendUrl('/user');

  axiosInstance
    .post(url, data)
    .then((response: AxiosResponse<IRegisterUserResponse>) => {
      dispatch(setIsLoading(false));

      if (response.status === 200) {
        dispatch(
          requestAuthentication(response.data.email, data.password, '/recipes')
        );
      }
    })
    .catch((axiosError: AxiosError<any>) => {
      dispatch(setIsLoading(false));

      const errors: any = axiosError.response?.data?.errors;
      console.error('unable to register user:', { errors });
    });
};

export const getRecipe = (id: number) => async (
  dispatch: Dispatch<any>
): Promise<any> => {
  dispatch(setIsLoading(true));

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${getAuthInfo(
    'accessToken'
  )}`;
  const url: string = await BackendUrl(`/recipe/get`);
  const params = { id };

  axiosInstance({ url, params })
    .then((response: AxiosResponse<IRecipeResponse>) => {
      dispatch(setIsLoading(false));
      if (response.status === 200) {
        dispatch(setCurrentRecipe(response.data));
      }
    })
    .catch((axiosError: AxiosError<any>) => {
      dispatch(setIsLoading(false));
      const errors: any = axiosError.response?.data?.errors;
      console.error('Error finding single recipe:', { errors });
    });
};
