import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';
import styles from './Button.module.scss';

interface IOwnProps {
  disabled?: boolean;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  RightIcon?: OverridableComponent<any>;
  submit?: boolean;
}

type IButtonProps = IOwnProps;

export const ButtonComp = ({
  disabled,
  label,
  onClick,
  RightIcon,
  submit = false,
}: IButtonProps) => (
  <button
    aria-disabled={disabled}
    className={styles.button}
    disabled={disabled}
    onClick={onClick}
    type={submit ? 'submit' : 'button'}
  >
    <span className={styles.label}>{label}</span>
    {RightIcon && (
      <RightIcon
        className={styles.rightIcon}
        style={{ fontSize: 25, color: 'white' }}
      />
    )}
  </button>
);

export default ButtonComp;
