import { useFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as Yup from 'yup';
import { requestAuthentication } from '../../data/redux/actions/api';
import IState from '../../data/redux/state';
import Button from '../Button/Button';
import { Field, PasswordField } from '../Field';

interface IDispatchProps {
  handleSubmitLogin: (email: string, password: string) => any;
}

export const LoginComp: React.FC<IDispatchProps> = ({ handleSubmitLogin }) => {
  const formik = useFormik({
    initialValues: {
      email: 'barry@smith.com',
      password: 'Th3Best!!',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email address is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: (values) => {
      handleSubmitLogin(values.email, values.password);
    },
  });

  return (
    <div>
      <h1>Login</h1>
      <form onSubmit={formik.handleSubmit}>
        <Field
          label="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isTouched={formik.touched.email}
          errors={formik.errors.email}
        />
        <PasswordField
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isTouched={formik.touched.password}
          errors={formik.errors.password}
        />
        <Button label="Login" submit />
      </form>
    </div>
  );
};

function mapDispatchToProps(
  dispatch: ThunkDispatch<IState, void, any>
): IDispatchProps {
  return {
    handleSubmitLogin: (email: string, password: string): any => {
      dispatch(requestAuthentication(email, password));
    },
  };
}

export default connect<any, IDispatchProps, any, any>(
  null,
  mapDispatchToProps
)(LoginComp);
