import React from 'react';
import { WithChildren } from '../../utils/types';
import Sidebar from '../Sidebar/Sidebar';
import styles from './Layout.module.scss';

interface IOwnProps {
  sidebar?: boolean;
}

type ILayoutProps = WithChildren<IOwnProps>;

export const LayoutComp = ({ children, sidebar = true }: ILayoutProps) => (
  <div className={styles.layout}>
    {sidebar && (
      <div className={styles.sidebar}>
        <Sidebar />
      </div>
    )}
    <main className={styles.main}>{children}</main>
  </div>
);

export default LayoutComp;
