import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Sidebar.module.scss';

const SidebarComp = () => (
  <div className={styles.sidebar}>
    <h1 className={styles.heading}>Mealy Planner</h1>
    <ul className={styles.nav}>
      <li>
        <NavLink
          activeClassName={styles.active}
          className={styles.navItem}
          to="/planner"
        >
          Planner
        </NavLink>
      </li>
      <li>
        <NavLink
          activeClassName={styles.active}
          className={styles.navItem}
          to="/recipes"
        >
          Recipes
        </NavLink>
      </li>
    </ul>
  </div>
);

export default SidebarComp;
