import React from 'react';
import { WithChildren } from '../../../utils/types';

interface IOwnProps {
  onInteract?: () => any;
}

type IInteractableWrapperProps = WithChildren<IOwnProps>;

export const InteractableWrapperComp = React.forwardRef<
  HTMLDivElement,
  IInteractableWrapperProps
>(({ onInteract, children }, ref) => (
  <div
    role="button"
    onClick={onInteract}
    onKeyPress={onInteract}
    tabIndex={0}
    ref={ref}
  >
    {children}
  </div>
));

export default InteractableWrapperComp;
