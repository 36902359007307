import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as Yup from 'yup';
import { generateMealPlan } from '../../../data/redux/actions/api';
import IState from '../../../data/redux/state';
import IMealPlanGenerationRequest from '../../../interfaces/requests/meal-plan-generation-request';
import Button from '../../Button/Button';
import { Field } from '../../Field';
import styles from './Generate.module.scss';

interface IDispatchProps {
  handleGenerateMealPlan: (data: IMealPlanGenerationRequest) => any;
}

export const GenerateMealPlanComp: React.FC<IDispatchProps> = ({
  handleGenerateMealPlan,
}) => (
  <Formik
    initialValues={{
      numberOfDays: 7,
    }}
    validationSchema={Yup.object({
      numberOfDays: Yup.number()
        .positive('Number of Days must be positive')
        .required('Number of Days must be a number'),
    })}
    onSubmit={({ numberOfDays }) => {
      const mealPlanRequestData: IMealPlanGenerationRequest = {
        numberOfDays,
      };

      handleGenerateMealPlan(mealPlanRequestData);
    }}
  >
    {({ handleSubmit, values, handleChange, handleBlur, touched, errors }) => (
      <form className={styles.form} onSubmit={handleSubmit}>
        <Field
          type="number"
          label="Number of Days"
          name="numberOfDays"
          value={values.numberOfDays}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.numberOfDays}
          errors={errors.numberOfDays}
        />
        <Button
          label="Generate"
          disabled={errors.numberOfDays !== undefined}
          submit
        />
      </form>
    )}
  </Formik>
);

function mapDispatchToProps(
  dispatch: ThunkDispatch<IState, void, any>
): IDispatchProps {
  return {
    handleGenerateMealPlan: (data): any => dispatch(generateMealPlan(data)),
  };
}

export default connect<any, IDispatchProps, any, IState>(
  null,
  mapDispatchToProps
)(GenerateMealPlanComp);
