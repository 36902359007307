import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getRecipeList } from '../../data/redux/actions/api';
import IState from '../../data/redux/state';
import history from '../../history';
import { IRecipe } from '../../interfaces/responses/recipe-list-response';
import CardGrid from '../CardGrid/CardGrid';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import styles from './Recipes.module.scss';

interface IStoreProps {
  recipes: IRecipe[];
  totalPages: number;
  isLoading: boolean;
}

interface IDispatchProps {
  handleGetRecipes: (searchTerm: string, pageNumber: number) => any;
}
type IRecipesProps = IDispatchProps & IStoreProps;

export const RecipesComp = ({
  recipes,
  totalPages,
  isLoading,
  handleGetRecipes,
}: IRecipesProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  // listens for a change in pageNumber or totalPages
  useEffect(() => {
    setHasMore(pageNumber < totalPages);
  }, [pageNumber, totalPages]);

  // listens for a change to pageNumber
  useEffect(() => {
    setLoading(true);
    handleGetRecipes('', pageNumber);
    setLoading(false);
  }, [handleGetRecipes, pageNumber]);

  const addRecipeCard = {
    title: 'Add Recipe',
    icon: AddCircleIcon,
    onClick: () => {
      history.push('/recipes/create');
    },
  };

  const observer = useRef<any>();
  const lastRecipeElement = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className={styles.recipes}>
      <CardGrid
        data={recipes}
        firstCard={addRecipeCard}
        infiniteScroll={{ lastElementCallback: lastRecipeElement }}
        enableNavigate
      />
    </div>
  );
};

function mapStateToProps(state: IState) {
  return {
    recipes: state.recipes.list,
    totalPages: state.recipes.totalPages,
    isLoading: state.isLoading,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<IState, void, any>
): IDispatchProps {
  return {
    handleGetRecipes: (searchTerm: string, pageNumber: number): any => {
      dispatch(getRecipeList(searchTerm, pageNumber, 12));
    },
  };
}

export default connect<IStoreProps, IDispatchProps, any, IState>(
  mapStateToProps,
  mapDispatchToProps
)(RecipesComp);
