import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import Rating from '@material-ui/lab/Rating';
import React from 'react';
import InteractableWrapper from '../utils/InteractableWrapper/InteractableWrapper';
import styles from './Card.module.scss';

interface IOwnProps {
  Icon?: OverridableComponent<any>;
  onClick?: () => void;
  title: string;
  rating?: number;
}

type ICardProps = IOwnProps;

export const CardComp = React.forwardRef<HTMLDivElement, ICardProps>(
  ({ onClick, title, rating, Icon }, ref) => (
    <InteractableWrapper ref={ref} onInteract={onClick}>
      <div
        className={`${styles.card} ${Icon !== undefined ? styles.icon : ''}`}
      >
        <div className={styles.title}>
          {Icon !== undefined && (
            <Icon style={{ fontSize: 30, color: 'white' }} />
          )}
          <span>{title}</span>
        </div>
        {(rating ?? 0) > 0 && <Rating value={rating} readOnly />}
      </div>
    </InteractableWrapper>
  )
);

export default CardComp;
