const getEnvironmentVariable = (variable: string | undefined): string => {
  if (variable === undefined || variable.length === 0) {
    console.error(
      `GetEnvironmentVariable: undefined or empty variable. Returning empty string`
    );
    return '';
  }
  return variable;
};

export default getEnvironmentVariable;
